import React, { Component } from 'react';
import { Router, Route } from 'react-router';
import { Redirect } from 'react-router';
import { Switch, Link } from 'react-router-dom'


import createHashHistory from 'history/createHashHistory';

import './App.css';

import Dropzone from 'react-dropzone'
import {Editor, EditorState, ContentState} from 'draft-js';
import localForage from "localforage";

import ReactPlayer from 'react-player'

import Modal from 'react-modal';

import {List} from 'immutable';

import axios from 'axios';


import JSZip from 'jszip';

import saveAs from 'file-saver';

const doWork = window.doWork;

const batchCreateScraps = window.batchCreateScraps;

const TEST_IMAGE = false;
const LOADING = 'loading';

const TYPE_IMAGE = 'TYPE_IMAGE';
const TYPE_TEXT = 'TYPE_TEXT';

const MAX_STORED_IMAGE_WIDTH = 1500;
const MAX_STORED_IMAGE_HEIGHT = 1500;


const DAYS_CONSIDERED_NEW = 2;

const SNAP_TO_GRID = false;

const WAYPOINT_RE = /\[(\d+)\]/g;

const NOP_FUNC = function() {};

let STATIC_IMAGES = [
    'radio.jpg',
    'clock1.png',
    'clock2.png',
    'lego.png',
    'richard feynman.png',
    'douglas adams.png',
    'dave.jpg',
];

let g_now = new Date().getTime();


// from mozilla
if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {

            let binStr = atob( this.toDataURL(type, quality).split(',')[1] ),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (let i = 0; i < len; i++ ) {
                arr[i] = binStr.charCodeAt(i);
            }

            callback( new Blob( [arr], {type: type || 'image/png'} ) );
        }
    });
}

// let g_db = new window.Database();
//
// @todo apply LRU or similar logic to cache
let IMAGE_OBJ = {};

function loadAssetFromLocalBlob(asset_key, fileType, assetUrlOnServer, onLoadCB) {
    let img = null;

    const isImage =  fileType.startsWith('image/');
    if(isImage) {
        img = new Image();
        img.onload = function () {

            if ((this.width > MAX_STORED_IMAGE_WIDTH)
                || (this.height > MAX_STORED_IMAGE_HEIGHT)) {
                // console.log('rescaling');

                let img = this;

                let MAX_WIDTH = MAX_STORED_IMAGE_WIDTH;
                let MAX_HEIGHT = MAX_STORED_IMAGE_HEIGHT;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                // @todo figure out how to reduce artifacts, etc.
                // especially on retina displays (2x etc)
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext("2d");
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(function (blob) {
                    localForage.setItem(asset_key, blob);

                    img.src = IMAGE_OBJ[asset_key] = URL.createObjectURL(blob);
                }, fileType);

                onLoadCB(asset_key);

            } else {
                onLoadCB(asset_key);
            }
        };
    }

    localForage.getItem(asset_key).then(function(result) {
        // console.log('hey have image', result, result.type);

        if(result === null) {
            // result is null when the key is not found
            // and it appears that the catch() function is called after

            cacheAssetFromServerInLocalStorage(asset_key, assetUrlOnServer, fileType,
                onLoadCB);

        } else {
            // @todo release the objectURL correctly
            let url = IMAGE_OBJ[asset_key] = URL.createObjectURL(result);
            if(isImage) {
                img.src = url;
            } else {
                // if it's not a image, notify the
                // scrap it's ready to render now
                onLoadCB(asset_key);
            }
        }
    }).catch(function(err) {


        cacheAssetFromServerInLocalStorage(asset_key, assetUrlOnServer, fileType,
            onLoadCB)

    });
}

function cacheAssetFromServerInLocalStorage(asset_key, assetUrlOnServer, fileType, onLoadCB) {
    let request = new XMLHttpRequest();
    request.responseType = "blob";

    let brd = this;

    request.onload = function () {

        // dumb hack to force the file type on the blob
        let blob = this.response.slice(0, this.response.size, fileType);

        let fileReader = new FileReader();


        fileReader.onload = function() {

            let arrayBuffer = this.result;

            // @todo this temporarily works around an issue in Safari
            // wherein the type of the blob wasn't static
            let blobWithType = new Blob([arrayBuffer], {type : fileType});

            // @todo release the objectURL correctly
            IMAGE_OBJ[asset_key] = URL.createObjectURL(blobWithType);

            localForage.setItem(asset_key, blobWithType).then(function () {

                return localForage.getItem(asset_key);

            }).then(function() {

                // @hack
                // @todo fix this once CloudKit allows asset uploads to shared databases
                //
                if(assetUrlOnServer.startsWith('https://www.twineandglue.com/upld/files/')) {
                    onLoadCB(asset_key, blob);
                }

                onLoadCB(asset_key);
            });
        };
        fileReader.readAsArrayBuffer(blob);
    };

    request.open("GET", assetUrlOnServer);
    request.send();
}


function createZipFileFromServerImages(zip, filename, assetUrlOnServer, fileType, onLoadCB) {
    let request = new XMLHttpRequest();
    request.responseType = "blob";

    request.onload = function () {

        // dumb hack to force the file type on the blob
        let blob = this.response.slice(0, this.response.size, fileType);

        let fileReader = new FileReader();

        fileReader.onload = function() {

            let arrayBuffer = this.result;

            zip.file(filename, arrayBuffer);

            onLoadCB();
        };
        fileReader.readAsArrayBuffer(blob);
    };

    request.open("GET", assetUrlOnServer);
    request.send();
}

function isImageCached(asset_key) {
    let img = IMAGE_OBJ[asset_key];

    return img && (img !== LOADING);
}

function getImageUrlForScrapKey(key, w, h, fileType, assetUrlOnServer, cbOnLoad) {
    let asset_key = key;

    let img_blob_url = IMAGE_OBJ[asset_key];

    if(img_blob_url) {
        if(img_blob_url === LOADING) {
            return null;
        } else {
            return img_blob_url;
        }
    } else {
        IMAGE_OBJ[asset_key] = LOADING;

        loadAssetFromLocalBlob(key, fileType, assetUrlOnServer, cbOnLoad);
    }

    return null;
}

class MyEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: EditorState.createWithContent(ContentState.createFromText(this.props.text))
        };

        this.onChange =   (editorState) => {
            this.setState({editorState})
        };

        this.onBlur = this.onBlur.bind(this);
    }

    __callOnChange() {
        let txt = this.state.editorState.getCurrentContent().getPlainText();

        // console.log("comparing", txt, this.props.text);

        if(txt !== this.props.text) {
            this.props.onChange(txt);
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        this.__callOnChange();
    }

    onBlur() {
        // console.log('BLURRRR');
        if(!this.__callOnChange()) {
            // @todo this is awful -- cleanup API
            // no notifications/changes
            // call onChange with null
            // this.props.onChange(null);
            this.props.onCancel();
        }
    }

    render() {
        // console.log("REDERING", this.props.text);
        return (
            <Editor
                ref={(comp) => { this.comp = comp; }}
                editorState={this.state.editorState}
                onChange={this.onChange}
                onBlur={this.onBlur}
            />
        );
    }

    focus() {
        if(this.comp) {
            this.comp.focus();
        }
    }
}

class Scrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPie: false,
        };

        if (this.props.fileType === 'application/pdf') {
            this.state.isLoading = false;
        } else {
            this.state.isLoading = true;
        }
    }

    handleMouseDown(e) {

        if ((e.target.tagName === 'A') || (e.target.tagName === 'SPAN')) {
            // @todo yuck: ignore link clicks, I guess
            // a bettter way to handle this?

        } else {
            this.props.onActive(this.props.name, this.props.changeTag, this.props.x, this.props.y,
                this.props.width,
                this.props.height,
                e.clientX, e.clientY, false);
        }
    }

    handleMouseDownOnResizer(e) {
        this.props.onActive(this.props.name, this.props.changeTag, this.props.x, this.props.y,
            this.props.width,
            this.props.height,
            e.clientX, e.clientY, true);
        e.stopPropagation();
        e.preventDefault();
    }

    componentWillReceiveProps(nextProps) {

        if(!this.props.isEditingAnnotation
            && nextProps.isEditingAnnotation) {
            // console.log("---> FOCU SANNOATE");
            this.setState({focusAnnotation: true});
        }
        else if(this.props.isEditingAnnotation
            && !nextProps.isEditingAnnotation) {
            // console.log("---> NO LONGER EIDTING");
            this.setState({focusAnnotation: false});
        }
    }

    render() {
        let styles = {
            top: this.props.y,
            left: this.props.x,
            zIndex: this.props.z,
            width: this.props.width,
            height: this.props.height
        };

        let cn = this.props.active ? 'scrap scrap-selected' : 'scrap';

        let img = '';
        let imageStyle = {
            // display: 'none',
            // opacity: 0.3
        };

        if (this.props.error) {
            cn += ' scrap-error';

        } else if (this.props.busy || this.props.isDirty) {
            cn += ' scrap-busy';
            imageStyle.opacity = 0.8;
        }

        if (this.props.active) {

        } else {

            let deltaHours = ((g_now - this.props.createdTimeStamp) / 1000.0) / 60.0 / 60.0 / 24.0;
            if (deltaHours < DAYS_CONSIDERED_NEW) {
                cn += ' scrap-hot';
            }
        }

        let displayAnnotation = this.props.annotation;
        let isLinked = false;
        let linkUrl = null;

        let waypoint = '';

        if(displayAnnotation) {

            const mwp = WAYPOINT_RE.exec(displayAnnotation);

            if(mwp) {
                displayAnnotation = displayAnnotation.replace(WAYPOINT_RE, '');
                // console.log("WAYPOINT", mwp);

                waypoint = (
                    <div className="waypoint-marker">
                        {mwp[1]}
                    </div>
                )
            }

            if(this.props.waypoint) {
                waypoint = (
                    <div className="waypoint-marker">
                        {this.props.waypoint}
                    </div>
                );
            }

            const reFindUrl = /\s*(http(s?):\/\/\S+)\s*/gi;
            const m = displayAnnotation.match(reFindUrl);

            if (m) {
                displayAnnotation = displayAnnotation.replace(reFindUrl, '');
                linkUrl = m[0];
                isLinked = true;
            }
        }

        // @todo clean up this state management
        //
        if (this.props.fileType === 'application/pdf') {

        }
        else if (this.props.fileType && this.props.fileType.startsWith('image/')) {

            if (this.props.imageUrl) {

                imageStyle.width = this.props.width; //this.state.image.width;
                imageStyle.height = this.props.height; // this.state.image.height;

                if (this.props.delayLoad) {
                    cn += ' scrap-delay-load';
                    // cn += ' scrap-busy';
                } else {
                    if(isLinked && !this.props.isEditable) {
                        img = (
                            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                                {img}
                             </a>
                        );
                    } else {
                        img = (
                            <img draggable="false"
                                 src={this.props.imageUrl}
                                 style={imageStyle}
                            />
                        );
                    }
                }
            } else {
                cn += ' scrap-loading';
            }
        }

        if (this.props.isZoomed) {
            styles.border = "10px solid black";
            styles.zIndex = 999999999999;
            styles.top = '5vw';
            styles.left = '10vw';
            styles.width = '80vw';//this.props.naturalWidth;
            styles.height = this.props.naturalHeight;
            styles.backgroundColor = 'white';
            imageStyle.width = this.props.naturalWidth;
            imageStyle.height = this.props.naturalHeight;
            imageStyle.marginLeft = 'auto';
            imageStyle.marginRight = 'auto';

            cn += ' scrap-zoom';
        }

        if (this.props.dullOut) {
            cn += ' scrap-dull';
        }

        if (
            !this.props.active
            || (this.props.active && !this.props.moving)
        ) {
            cn += ' scrap-settling';
        }

        if (this.state.isSnappingOut) {
            cn += ' scrap-unsnapping';
        }

        if (this.props.isCandidate) {
            // for(let stx of this.props.isCandidate){
            //     console.log(stx);
            // styles[stx] = this.props.isCandidate[stx];
            // }
            // styles.border = '1px solid ' + this.props.isCandidate;
            // cn += ' scrap-candidate';
        }

        let marginMarker = '';

        if (this.props.showMarginLeft) {
            // if(true) {
            let ss = {
                width: this.props.marginLeft,
                left: -this.props.marginLeft,
                // top: this.props.height /2,
            };
            marginMarker = (
                <div className="scrap-margin-marker" style={ss}>
                    {this.props.marginLeft}
                </div>
            );
        }
        else if (this.props.showSnapLeft) {

            if (this.props.snapTop > 0) {

                let ss = {
                    width: 1,
                    height: this.props.snapTop, // this.props.marginLeft,
                    left: -1, // -this.props.marginLeft,
                    top: -this.props.snapTop,
                    // width: 1,
                };
                marginMarker = (
                    <div className="scrap-snap-marker" style={ss}>
                        {this.props.snapTop}
                    </div>
                );
            }
            else if (this.props.snapTop < 0) {

                let ss = {
                    width: 1,
                    height: -this.props.snapTop, // this.props.marginLeft,
                    left: -1, // -this.props.marginLeft,
                    top: this.props.height,
                    // width: 1,
                };
                marginMarker = (
                    <div className="scrap-snap-marker" style={ss}>
                        {-this.props.snapTop}
                    </div>
                );
            }
        }

        if (this.props.moving && this.props.active) {
            cn += ' scrap-moving';
        }

        let annotationArea = '';

        if (
            (this.props.type !== TYPE_TEXT) // annotation text field is used to store the text of TEXT scraps :/
            && (this.props.annotation || this.props.isEditingAnnotation)) {

            let clsname = 'scrap-annotation' + (this.props.isEditingAnnotation ? ' scrap-editing-annotation' : '');

            if(this.props.isEditingAnnotation && this.props.isEditable) {

                // console.log('RENDER ANNOTATION', this.props.annotation);

                annotationArea = (
                    <div className={clsname}>
                        <MyEditor
                            ref={input => input && this.state.focusAnnotation && input.focus()}
                            onChange={this.props.onAnnotationChange.bind(this, this)}
                            onCancel={this.props.onAnnotationCancel.bind(this, this)}
                            text={this.props.annotation}
                        />
                    </div>
                );
            } else {
                let fnEditBegin = NOP_FUNC;

                if(this.props.isEditable) {
                    fnEditBegin = (e) => {
                        if(e.target.tagName !== 'A') {
                            this.props.onBeginAnnotation(this);
                            e.preventDefault();
                        }
                    };
                }


                let linkTag = '';

                if(isLinked) {
                    linkTag = (
                            <a className="annotationLink" href={linkUrl} target="_blank" rel="noopener noreferrer">
                                ↗
                            </a>
                    );
                }

                annotationArea = (
                    <div>
                        <div className={clsname}
                             onMouseDown={fnEditBegin}
                        >
                            {displayAnnotation}

                            {linkTag}
                        </div>
                    </div>
                );
            }
        }


        let resizer = '';

        if(this.props.isEditable) {
            resizer = (
                <div className="image-handle"
                     onMouseDown={this.handleMouseDownOnResizer.bind(this)}
                />
            );
        }

        let mouseDownFn = NOP_FUNC;

        if(this.props.isEditable) {
            mouseDownFn = this.handleMouseDown.bind(this);
        }

        let scrapContents = '';

        if (this.props.fileType === 'application/pdf') {

            let downloadURL = this.props.imageUrl.replace('${f}', encodeURIComponent(this.props.description));

            let scrap = this;

            scrapContents = (
                    <div className="blob-frame">
                        {this.props.error ? "couldn't load" : ""}
                        <a className="blob-download-link" href={downloadURL} target="zz">{this.props.description}</a>
                        <br />
                        <div className="blob-meta">
                            {this.props.fileType}
                        </div>
                    </div>
            );
        }
        else if (this.props.type === TYPE_TEXT) {


            // @todo is active same as isediting?
            if(this.props.active) {
                let text = this.props.annotation ? this.props.annotation : '';

                scrapContents = (
                        <MyEditor ref={input => input && this.state.focusAnnotation && input.focus()}
                              text={text}
                              onChange={this.props.onEditingTextDone.bind(this, this)}
                              onCancel={this.props.onEditingTextCancel.bind(this, this)}
                    />
                );
            } else {
                scrapContents = (
                    <div>
                        {this.props.annotation}
                    </div>
                );
            }

        }
        else if (
            (this.props.fileType && this.props.fileType.startsWith('image/'))) {

            scrapContents = (
                    <div className="image-frame" style={imageStyle}>
                        {img}
                        {this.props.error ? "couldn't load" : ""}
                        <div className="image-annotation">
                        {this.props.description}<br />{this.props.fileType}
                        </div>
                    </div>
            );
        }
        else if (this.props.fileType && this.props.fileType.startsWith('video/')) {
            imageStyle.width = this.props.width;
            imageStyle.height = this.props.height;

            scrapContents = (
                    <div className="image-frame" style={imageStyle}>
                            {this.props.error ? "couldn't load" : ""}
                        <ReactPlayer url={this.props.imageUrl}
                                     width={this.props.width}
                                     height={this.props.height}
                                     controls />
                    </div>
            );
        }
        else {
            console.log('--->', this.props.type, this.props.fileType);
            return (
              <div>empty</div>
            );
        }

        return (
            <div className={cn}
                 onMouseDown={mouseDownFn}
                 style={styles}>
                {waypoint}
                {marginMarker}
                {scrapContents}
                {annotationArea}
                {resizer}
            </div>
        );
    }
}

class Board extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            // scraps: OrderedMap(),
            scraps:[],
            shiftDown: false,
            canvas: {width: 100, height: 100},
            isLoading: true,
            currentWayPoint: -1,
        };

        this.textCount = 0;
        this.maxX = 1024;
        this.maxY = 512;
        this.lastScrollTime = 0;

        if(this.props.isPrivate) {
            this.dbType = 'PRIVATE';

        } else if(this.props.isShared) {
            this.dbType = 'SHARED';

        } else {
            this.dbType = 'PUBLIC';
        }

        console.log("-------->BOARD NEW", this.dbType, this.props.ownerRecordName);

        this.db = new window.Database(this.dbType, this.props.ownerRecordName);

        this.db.fetchBoardAndScraps(
                    this.props.recordName,
                    this.updateBoard.bind(this),
                    this.__convertRecordsToScraps.bind(this));

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.__batchHandleOnScroll = this.__batchHandleOnScroll.bind(this);
        this.onResize = this.onResize.bind(this);

        this.updateBoardName = this.updateBoardName.bind(this);

        this.handleKeyboardShortcuts = this.handleKeyboardShortcuts.bind(this);

        this.vScrollX = 0;
        this.vScrollY = 0;

        // @haha HAHAHAHAHAHAHAHAHAHA  ha.
        window.singleRecordUpdate = this.updateSingleRecord.bind(this);
    }

    updateBoardName(name) {
        this.setState({name: name});

        this.db.updateBoardName(this.props.recordName,
        name, function() {});
    }

    updateBoard(board) {
        this.setState({name: board.name});
    }

    updateSingleRecord(data) {

        if (data.fields.Board.value.recordName !== this.props.recordName) {
            // @todo properly ignore changes to other boards
            // @todo can this be entirely skipped with better query settings? probably.

            console.log('Ignoring CHANGE');
            return;
        }

        for(let z of this.state.scraps) {
            if (z.key === data.recordName) {

                // console.log("MATCH MATH", z.changeTag, data.recordChangeTag);

                if(z.changeTag === data.recordChangeTag) {
                    // console.log("GOT NOTI but ignoring since it's up-to-date")
                    return;
                }

                let imageUrl = '';

                if (data.fields.Image) {
                    imageUrl = data.fields.Image.value.downloadURL;
                }

                z.changeTag = data.recordChangeTag;
                z.description = data.fields.Description.value;
                z.annotation = data.fields.annotation ? data.fields.annotation.value : '';
                z.x = data.fields.x.value;
                z.y = data.fields.y.value;
                z.z = data.fields.z.value;
                z.width = data.fields.width.value;
                z.height = data.fields.height.value;
                z.imageUrl = imageUrl;
                z.isDirty = false;
                z.busy = false;

                this.setState({scraps:this.state.scraps});

                return;
            }
        }

        // new record
        // @todo mutating the state diretly is probably BAD?

        this.state.scraps.push(this.__makeScrap(data));
        this.setState({scraps: this.state.scraps});
    }

    __makeScrap(z) {
        let imageUrl = '';

        if(z.fields.Image) {
            imageUrl = z.fields.Image.value.downloadURL;

        } else if (z.fields.tempFileURL) {
            imageUrl = 'https://www.twineandglue.com/upld/files/'
                       + z.fields.tempFileURL.value;
        }

        let scrapType = z.fields.type ? z.fields.type.value : TYPE_IMAGE;

        // patch old records to always have file (MIME) type
        let fileType = z.fields.fileType ? z.fields.fileType.value : null;

        if(!fileType) {
            if (scrapType === TYPE_IMAGE) {
                fileType = 'image/png'; // this doesn't matter in older boards
            } else {
                fileType = '';
            }
        }

        return ({
            createdTimeStamp: z.created.timestamp,
            lastModifiedTimeStamp: z.modified.timestamp,
            key: z.recordName,
            changeTag: z.recordChangeTag,
            type: scrapType,
            fileType: fileType,
            description: z.fields.Description.value,
            x: z.fields.x ? z.fields.x.value : 0,
            y: z.fields.y ? z.fields.y.value : 0,
            z: z.fields.z ? z.fields.z.value : 0,
            annotation: z.fields.annotation ? z.fields.annotation.value : '',
            width: z.fields.width.value,
            height: z.fields.height.value,
            naturalWidth: z.fields.naturalWidth.value,
            naturalHeight: z.fields.naturalHeight.value,
            imageUrl: imageUrl,
            isDirty: false,
            isZoomed: false
        });
    }

    __convertRecordsToScraps(records) {
        let scraps = [];

        this.maxZ = 0;

        for(let z of records) {
            let sc = this.__makeScrap(z);

            scraps.push(sc);

            if(this.maxZ < sc.z) {
                this.maxZ = sc.z;
            }
        }

        this.setState({scraps, isLoading: false});
    }

    mapClientToPaper(cx, cy, snap) {


return {x:cx, y:cy};

        let vTop = 0;
        let vLeft = 0;

        let x = (cx - vLeft);// + this.vScrollX;
        let y = (cy - vTop );// + this.vScrollY;

        if(snap) {
            let SNAP_POINT = 10;
            x += parseInt(SNAP_POINT / 2);
            y += parseInt(SNAP_POINT / 2);
            x -= x % SNAP_POINT;
            y -= y % SNAP_POINT;
        }

        return {x:x, y:y};
    }

    __onRecordUpdate(data) {

        for(let z of this.state.scraps) {
            if (z.key === data.recordName) {

                let imageUrl = '';

                if (data.fields.Image) {
                    imageUrl = data.fields.Image.value.downloadURL;
                }

                z.changeTag = data.recordChangeTag;
                z.description = data.fields.Description.value;
                z.x = data.fields.x.value;
                z.y = data.fields.y.value;
                z.z = data.fields.z.value;
                z.width = data.fields.width.value;
                z.height = data.fields.height.value;
                z.imageUrl = imageUrl;
                z.isDirty = false;

                this.setState({scraps:this.state.scraps});

                return;
            }
        }

    }

    handleMouseDrop(e) {

        let rect = this.canvasDOM.getBoundingClientRect();

        this.mouseX = e.clientX - rect.left;
        this.mouseY = e.clientY - rect.top;
    }

    clearSelection() {
        this.numSelections = 0;
        // @todo direct state manipulation is bad
        for(let z of this.state.scraps) {
            z.active = false;
            z.selectionNumber = -1;
            z.isEditingAnnotation = false;
        }
    }

    addSelectionToActive(key, onlySelection) {
        if(onlySelection) {
            this.numSelections = 0;
        }

        // @todo direct state manipulation is bad
        for(let z of this.state.scraps) {
            if(z.key === key) {
                z.active = true;
                z.selectionNumber = this.numSelections;

                this.numSelections++;

            } else if (onlySelection) {
                z.active = false;
                z.selectionNumber = -1;
            }
        }
    }

    setActiveScrap(key, changeTag, x, y, w, h, cx, cy, isResizing) {
        if(isResizing) {

            this.addSelectionToActive(key, true);

            this.setState({scraps: this.state.scraps});
            this.setState({moving: false, resizing: true});

            return;
        }

        this.lastMouseDownScrapKey = key;

        this.setState({moving: true, resizing: false});
        this.setState({scraps: this.state.scraps});
    }

    computeMarginsForScrap(scrap, candidates) {
        for (let z of candidates) {
            if (scrap !== z) {
                // top margin
                let topToBottom = (z.y + z.height) - scrap.y;
                if(topToBottom >= 0) {
                    let smallerX = (z.x < scrap.x) ? z.x : scrap.x;
                    // let smallerX = (z.x < scrap.x) ? z.x : scrap.x;
                }
            }
        }
    }

    closenessMetric(scrapA, scrapB) {
        let Ax1 = scrapA.x;
        let Ax2 = scrapA.x + scrapA.width;
        let Ay1 = scrapA.y;
        let Ay2 = scrapA.y + scrapA.height;

        let Bx1 = scrapB.x;
        let Bx2 = scrapB.x + scrapB.width;
        let By1 = scrapB.y;
        let By2 = scrapB.y + scrapB.height;

        
        let minx = Ax1 < Ax2 ? Ax1 : Ax2;
        minx = Bx1 < minx ? Bx1 : minx;
        minx = Bx2 < minx ? Bx2 : minx;

        let maxx = Ax1 > Ax2 ? Ax1 : Ax2;
        maxx = Bx1 > maxx ? Bx1 : maxx;
        maxx = Bx2 > maxx ? Bx2 : maxx;

        let miny = Ay1 < Ay2 ? Ay1 : Ay2;
        miny = By1 < miny ? By1 : miny;
        miny = By2 < miny ? By2 : miny;

        let maxy = Ay1 > Ay2 ? Ay1 : Ay2;
        maxy = By1 > maxy ? By1 : maxy;
        maxy = By2 > maxy ? By2 : maxy;


        let big_width = maxx - minx;
        let big_height = maxy - miny;
        let big_area = big_width * big_height;

        let area = scrapA.width *scrapA. height + scrapB.width *scrapB.height;

        // @todo why the hell is this reasonable?
        // it's probably best to do basic geometry
        return area/big_area;
    }

    handleMouseDown(e) {

        if(e.button !== 0) {
            return;
        }

        this.mouseMoved = false;

        this.previousX = e.clientX;
        this.previousY = e.clientY;

        this.mouseDownX = e.clientX;
        this.mouseDownY = e.clientY;

        this.mouseDownWhenEditingTextNode = false;

        if(e.target === this.canvasDOM) {

            console.log('clearing selection');

            // @todo direct state manipulation is bad
            for(let z of this.state.scraps) {
                if(z.isEditingText) {
                    this.mouseDownWhenEditingTextNode = true;
                    break;
                }
            }

            this.clearSelection();

            this.setState({scraps: this.state.scraps});

            this.lastMouseDownScrapKey = null;

            let rect = this.canvasDOM.getBoundingClientRect();

            this.mouseDownX = e.clientX - rect.left;
            this.mouseDownY = e.clientY - rect.top;

            this.setState({

                startedAreaSelection: true,
                areaSelectionEnd: {
                    x:e.clientX - rect.left,
                    y:e.clientY - rect.top
                }
            });

            e.preventDefault();

        } else {
            this.setState({startedAreaSelection: false});
            console.log('mouse down on scrap', e.target);
        }
    }

    handleMouseMove(e) {
        if(e.button !== 0) {
            return;
        }

        this.mouseMoved = true;

        let dx = e.clientX - this.previousX;
        let dy = e.clientY - this.previousY;

        this.previousX = e.clientX;
        this.previousY = e.clientY;

        this.movingLeft  = (this.movingLeft  && (dx === 0)) || (dx < 0);
        this.movingRight = (this.movingRight && (dx === 0)) || (dx > 0);

        this.movingUp   = (this.movingUp   && (dy === 0)) || (dy < 0);
        this.movingDown = (this.movingDown && (dy === 0)) || (dy > 0);

        // if(this.movingLeft)
        //     console.log('moving-left');
        //
        // if(this.movingRight)
        //     console.log('moving-right');

        let cancelDefault = this.state.startedAreaSelection
                        || this.state.moving
                        || this.state.resizing;

        if(this.state.startedAreaSelection) {

            let rect = this.canvasDOM.getBoundingClientRect();
            let xx = e.clientX - rect.left;
            let yy = e.clientY - rect.top;

            let leftBound = this.mouseDownX;
            let rightBound = xx;

            let topBound =  this.mouseDownY;
            let bottomBound =  yy;

            // console.log(rect, 'xxx ' + this.vScrollX + ' ' + this.vScrollY);

            this.clearSelection();

            for (let z of this.state.scraps) {

                let w = z.width;
                let h = z.height;

                let vx = z.x + w;
                let vy = z.y + h;

                if (
                    (
                        ((z.x >= leftBound) && (z.x <= rightBound))
                        || ((vx >= leftBound) && (vx <= rightBound))
                        || ((z.x <= leftBound) && (vx >= rightBound))
                    )
                    &&
                    (
                        ((z.y >= topBound) && (z.y <= bottomBound))
                        || ((vy >= topBound) && (vy <= bottomBound))
                        || ((z.y <= topBound) && (vy >= bottomBound))
                    )
                ) {
                    this.addSelectionToActive(z.key, false);
                }
            }

            this.setState({
                areaSelectionEnd: {
                    x:xx,
                    y:yy
                }
            });

        }
        else if(this.state.moving) {

            // @todo this is all very bad state mgmt -- cleanup
            if(this.state.shiftDown) {
                this.addSelectionToActive(this.lastMouseDownScrapKey, false);

            } else {
                let singleSelectLastScrap = false;

                for (let z of this.state.scraps) {
                    if (
                        (z.key === this.lastMouseDownScrapKey)
                        && !z.active) {
                        singleSelectLastScrap = true;
                        break;
                    }
                }

                if (singleSelectLastScrap) {
                    this.addSelectionToActive(this.lastMouseDownScrapKey, true);
                }
            }

            let movingScrap = null;

            let px = null;
            let py = null;

            let candidateScraps = [];
            let inViewScraps = [];

            let numSelected = 0;

            // @todo direct state manipulation is bad
            for(let z of this.state.scraps) {
                if (z.active) {
                    py = z.y;
                    px = z.x;
                    z.x = z.x + dx;
                    z.y = z.y + dy;
                    movingScrap = z;
                    delete movingScrap.isSnappingX;
                    delete movingScrap.isSnappingY;

                    numSelected++;
                }
                z.marginLeft = null;
                z.showMarginLeft = false;
                z.showSnapLeft = false;
                z.isCandidate = false;
            }

            // @todo direct state manipulation is bad
            for(let z of this.state.scraps) {
                let w = z.width;
                let h = z.height;

                let vx = z.x + w;
                let vy = z.y + h;

                // setup a halo around the viewport to reduce images
                // snapping in on load
                //
                let leftBound = this.vScrollX; // - this.vRect.width / 4;
                let rightBound = this.vScrollX + this.vRect.width; // + this.vRect.width / 4;

                let topBound = this.vScrollY; // - this.vRect.height / 4;
                let bottomBound = this.vScrollY + this.vRect.height; // + this.vRect.height / 4;

                if (
                    (
                        ((z.x >= leftBound) && (z.x <= rightBound))
                        || ((vx >= leftBound) && (vx <= rightBound))
                        || ((z.x <= leftBound) && (vx >= rightBound))
                    )
                    &&
                    (
                        ((z.y >= topBound) && (z.y <= bottomBound))
                        || ((vy >= topBound) && (vy <= bottomBound))
                        || ((z.y <= topBound) && (vy >= bottomBound))
                    )
                ) {
                    if(movingScrap !== z) {
                        let ocx = z.x + z.width / 2;
                        let ocy = z.y + z.height / 2;

                        let xx = (ocx - (movingScrap.x + movingScrap.width / 2 ));
                        let yy = (ocy - (movingScrap.y + movingScrap.height / 2));
                        xx *= xx;
                        yy *= yy;


                       // @todo better distance metric?

                        if(this.closenessMetric(movingScrap, z) > 0.3) {
                            candidateScraps.push(z);
                            z.isCandidate = {border: '1px solid orange'};
                        }

                        inViewScraps.push(z);
                    }
                }
            }

            // console.log('candidate size', candidateScraps.length);

            const SNAP_WINDOW = 10;
            const SNAP_SPACING = 4;

            if(movingScrap && !this.state.shiftDown && numSelected === 1) {
                if(this.movingLeft) {

                    // left side of scrap against left side of another
                    for (let z of candidateScraps) {
                        if (z !== movingScrap) {
                            let dx = (z.x - movingScrap.x);
                            if ((dx > 0) && (dx < SNAP_WINDOW)) {
                                movingScrap.isSnappingX = true;
                                movingScrap.snappedX = z.x;

                                if(movingScrap.y > (z.y + z.height)) {
                                    movingScrap.snapTop = movingScrap.y - (z.y + z.height);
                                }
                                else if((movingScrap.y + movingScrap.height) < (z.y)) {
                                    movingScrap.snapTop = (movingScrap.y + movingScrap.height) - z.y;
                                }
                                movingScrap.showSnapLeft = true;

                                break;
                            }
                        }
                    }

                    if(!movingScrap.isSnappingX) {

                        // left side of scrap against *right* side of another
                        // this assumes the user might want to space out things
                        // with a margin inferred from the candidate scraps neighbors


                        // 1) compute known margins of inViewScraps
                        // @todo probably cache this stuff if there's no movement
                        // @todo deciding which ones to recompute probably gets messy, though
                        for (let z of inViewScraps) {
                            z.marginLeft = null;
                            z.attach = null;
                            for (let z2 of inViewScraps) {
                                if (z !== z2)  {
                                    let xx = (z.x - (z2.x + z2.width));
                                    let yy = Math.abs(z.y - z2.y);

                                    if ((xx >= 0) && yy < 100 /* how aligned is it? */) {
                                        if(z.marginLeft === null) {
                                            z.marginLeft = xx;
                                            z.attach = z2;
                                        } else if(z.marginLeft > xx){
                                            z.marginLeft = xx;
                                            z.attach = z2;
                                        }
                                    }
                                }
                            }
                            if(z.marginLeft === null) {
                                z.marginLeft = 0;
                            }
                        }

                        for (let z of candidateScraps) {
                            if (z !== movingScrap) {
                                let heuristicMarginToScrap = (z.x + z.width + z.marginLeft) - movingScrap.x;

                                if ((heuristicMarginToScrap > 0) && (heuristicMarginToScrap < SNAP_WINDOW)) {
                                    movingScrap.isSnappingX = true;
                                    movingScrap.snappedX = (z.x + z.width + z.marginLeft);

                                    z.isCandidate = {borderLeft: '1px solid orange'};
                                    z.showMarginLeft = true;

                                    movingScrap.marginLeft = z.marginLeft;
                                    movingScrap.showMarginLeft = true;
                                    if(z.attach) {
                                        z.attach.isCandidate = {borderRight: '1px solid pink'};
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }
                else if(this.movingRight) {
                    for (let z of candidateScraps) {
                        if (z !== movingScrap) {
                            let xr = movingScrap.x + movingScrap.width;
                            let oxr = z.x + z.width;

                            let dx = (xr - oxr);

                            if ((dx > 0) && (dx < SNAP_WINDOW)) {
                                movingScrap.isSnappingX = true;
                                movingScrap.snappedX = oxr - movingScrap.width;
                                break;
                            }
                        }
                    }
                    if(!movingScrap.isSnappingX) {
                        for (let z of candidateScraps) {
                            if (z !== movingScrap) {
                                let xr = movingScrap.x + movingScrap.width;
                                let oxr = z.x - SNAP_SPACING;

                                let dx = (xr - oxr);

                                if ((dx > 0) && (dx < SNAP_WINDOW)) {
                                    movingScrap.isSnappingX = true;
                                    movingScrap.snappedX = oxr - movingScrap.width;
                                    break;
                                }
                            }
                        }
                    }
                }

                if(this.movingUp) {
                    for (let z of candidateScraps) {
                        if (z !== movingScrap) {
                            let dy = (z.y - movingScrap.y);
                            if ((dy > 0) && (dy < SNAP_WINDOW)) {
                                movingScrap.isSnappingY = true;
                                movingScrap.snappedY = z.y;
                                break;
                            }
                        }
                    }
                    if(!movingScrap.isSnappingY) {
                        for (let z of candidateScraps) {
                            if (z !== movingScrap) {
                                let dy = (z.y + z.height + SNAP_SPACING) - movingScrap.y;

                                if ((dy > 0) && (dy < SNAP_WINDOW)) {
                                    movingScrap.isSnappingY = true;
                                    movingScrap.snappedY = z.y + z.height + SNAP_SPACING;
                                    break;
                                }
                            }
                        }
                    }
                }
                else if(this.movingDown) {
                    for (let z of candidateScraps) {
                        if (z !== movingScrap) {
                            let yr = movingScrap.y + movingScrap.height;
                            let oyr = z.y + z.height;

                            let dy = (yr - oyr);

                            if ((dy > 0) && (dy < SNAP_WINDOW)) {
                                movingScrap.isSnappingY = true;
                                movingScrap.snappedY = oyr - movingScrap.height;
                                break;
                            }
                        }
                    }
                    if(!movingScrap.isSnappingY) {
                        for (let z of candidateScraps) {
                            if (z !== movingScrap) {
                                let yr = movingScrap.y + movingScrap.height;
                                let oyr = z.y - SNAP_SPACING;

                                let dy = (yr - oyr);

                                if ((dy > 0) && (dy < SNAP_WINDOW)) {
                                    movingScrap.isSnappingY = true;
                                    movingScrap.snappedY = oyr - movingScrap.height;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            this.setState({scraps: this.state.scraps});

        }
        else if(this.state.resizing) {

            for(let z of this.state.scraps) {
                if(z.active) {
                    let w = z.width + dx;
                    let h = z.height + dy;

                    if (!this.state.shiftDown) {
                        let a_w = z.naturalWidth;
                        let a_h = z.naturalHeight;

                        if (a_w > a_h) {
                            h = parseInt(w * a_h / a_w);
                        } else {
                            w = parseInt(h * a_w / a_h);
                        }

                        let x = z.x;
                        let y = z.y;

                        let hx = x + w;
                        let hy = y + h;

                        w = hx - x;
                        h = hy - y;

                        z.width = w;
                        z.height = h;
                    }

                    z.width = w;
                    z.height = h;

                    // @todo direct modification of state is bad
                    this.setState({scraps: this.state.scraps});

                    break;
                }
            }
        }

        if(cancelDefault) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handleMouseUp(e) {
        if(e.button !== 0) {
            return;
        }

        if (!this.mouseMoved) {

            if(
                this.state.startedAreaSelection
                && !this.mouseDownWhenEditingTextNode
            ) {
                // @todo this is poorly named -- this is how a click (without mouse movement)
                // is detected
/*
                console.log('--> hrererserse');

                let w = 100;
                let h = 50;

                let bs = {
                    key: "placeholder_textnode_" + (this.textCount++),
                    changeTag: "tagatags",
                    description: "superdupertext",
                    x: e.clientX,
                    y: e.clientY,
                    z: this.maxZ,
                    naturalWidth: w,
                    naturalHeight: h,
                    width: w,
                    height: h,
                    imageUrl: '',
                    type: TYPE_TEXT,
                    busy: false,
                    isNotSaved: true,
                    isEditingText: true,
                    active: true
                };

                this.state.scraps.push(bs);

                this.setState({scraps: this.state.scraps});
*/
            } else {

                if (this.state.shiftDown) {
                    this.addSelectionToActive(this.lastMouseDownScrapKey, false);

                } else {
                    this.addSelectionToActive(this.lastMouseDownScrapKey, true);
                }
            }

            this.setState({moving: false});
            this.setState({startedAreaSelection: false});
            return;
        }

        if(this.state.startedAreaSelection) {

            this.setState({startedAreaSelection: false});

        } else if (this.state.moving) {

            let changedScraps = [];

            // @todo direct manipulation of state is bad
            for (let z of this.state.scraps) {
                if (z.active) {
                    let x = z.x;
                    let y = z.y;

                    if(!this.state.shiftDown) {
                        let c = this.mapClientToPaper(z.x, z.y, true);
                        x = c.x;
                        y = c.y;
                    }

                    this.maxZ++;

                    z.isDirty = true;
                    z.x = z.isSnappingX ? z.snappedX : x;
                    z.y = z.isSnappingY ? z.snappedY : y;
                    z.z = this.maxZ;

                    delete z.marginLeft;
                    delete z.isSnappingX;
                    delete z.snappedX;
                    delete z.isSnappingY;
                    delete z.snappedY;

                    changedScraps.push(z);
                }
                z.showMarginLeft = false;
                z.showSnapLeft = false;
            }

            let that = this;

            this.db.updateScraps(changedScraps,
                function (records) {
                    for (let record of records) {
                        that.__onRecordUpdate(record);
                    }
                }
            );

            this.setState({scraps: this.state.scraps});
            this.setState({moving: false});

        } else if (this.state.resizing) {

            for (let z of this.state.scraps) {
                if (z.active) {
                    z.isDirty = true;

                    this.maxZ++;

                    let that = this;

                    that.db.updateScraps([z],
                        function (records) {
                            for (let record of records) {
                                that.__onRecordUpdate(record);
                            }
                        });

                    break;
                }
            }

            this.setState({scraps: this.state.scraps, resizing: false});
        }
    }

    createScrapsFromFiles(files, readyToUploadCB) {

        let records = [];
        let scraps = [];

        let boardRecordName = this.props.recordName;

        let imagesToPreview = 0;

        let totalFilesToUploads = 0;

        for(let file of files) {
            if (file.type.startsWith('image/')) {
                imagesToPreview++;
            }
            totalFilesToUploads++;
        }

        let hasOnlyNonImagesToUpload = (imagesToPreview === 0);

        let i = 0;

        for(let file of files) {

            let x = parseInt(this.mouseX + i * 30);
            let y = parseInt(this.mouseY + i * 30);

            let w = 300;
            let h = 100;

            let record = {
                recordType: "Scrap",
                parent: {
                    recordName: boardRecordName
                },
                fields: {
                    Board: {
                        value: {
                            recordName: boardRecordName
                        }
                    },

                    x: {value: x},
                    y: {value: y},
                    z: {value: this.maxZ},

                    // @todo scale down to fit screen
                    width: {value: w},
                    height: {value: h},
                    naturalWidth: {value: w},
                    naturalHeight: {value: h},

                    Description: {value: file.name},
                    Image: {value: file},
                    fileType: {value: file.type}
                }
            };

            let bs = {
                key: "placeholder_" + i,
                changeTag: "tagatags",
                description: file.name,
                x: x,
                y: y,
                z: this.maxZ,
                naturalWidth: w,
                naturalHeight: h,
                width: w,
                height: h,
                fileType: file.type,
                imageUrl: file.preview,
                busy: true
            };

            this.maxZ++;

            if (file.type.startsWith('image/')) {

                this.__loadPreviewImage(file, record, bs, function() {
                    imagesToPreview--;
                    if(imagesToPreview === 0) {
                        readyToUploadCB(records, scraps);
                    }
                });
            }

            scraps.push(bs);
            records.push(record);

            i++;
        }

        if(hasOnlyNonImagesToUpload) {
            readyToUploadCB(records, scraps);
        }

        return records;
    }

    __temporarilyUploadAssets(file, record,
                                 doneCB) {

        let data = new FormData();
        data.append('file', file);

        const DOMAIN = 'https://www.twineandglue.com';
        axios.post(DOMAIN + '/upld/', data).then(function(response) {

            // @todo remove this hack once Apple fixes uploading assets
            // to iCloud
            delete record.fields.Image;

            record.fields.tempFileURL = {
                value: response.data.filename
            };

            doneCB();
        });
    }

    __loadPreviewImage(file, record, bs, doneCB) {
        let i = new Image();

        i.onerror = function () {
            // @here correctly handle error scraps and/or error reporting
        };

        let that = this;

        i.onload = function () {
            console.log('image dimension', i.width, i.height,
                that.vRect.width, that.vRect.height);

            let ow = i.width;
            let oh = i.height;

            let w = i.width;
            let h = i.height;

            let a_w = i.width;
            let a_h = i.height;

            if (a_w > a_h) {
                if(w > that.vRect.width / 2) {
                    w = parseInt(that.vRect.width / 2);
                }
                h = parseInt(w * a_h / a_w);
            } else {
                if(h > that.vRect.height / 2) {
                    h = parseInt(that.vRect.height / 2);
                }
                w = parseInt(h * a_w / a_h);
            }

            bs.width = w;
            bs.height = h;

            record.fields.width.value = w;
            record.fields.height.value = h;

            record.fields.naturalWidth.value = ow;
            record.fields.naturalHeight.value = oh;

            if(that.dbType === 'SHARED') {
                that.__temporarilyUploadAssets(file, record,
                                                doneCB);
            } else {
                doneCB();
            }
        };

        i.src = file.preview;
    }

    onDrop(files, rejects, e) {

        let that = this;

        this.createScrapsFromFiles(files,
                        function(recs, newScraps) {
                            let size = recs.length;

                            // @todo mutating state directly is bad
                            Array.prototype.push.apply(that.state.scraps, newScraps);

                            that.setState({scraps: that.state.scraps});

                            that.db.createScraps(recs, function(records) {

                                let i = 0;

                                for (let r of records) {

                                    // console.log('setting', r.recordName);

                                    // @todo mutating the state diretly is probably BAD?
                                    let z = newScraps[i];
                                    let data = r;

                                    //
                                    // @todo centralize these data <--> model transformations
                                    //

                                    z.key = data.recordName;

                                    // this is important so that any duplicate
                                    // change notification can be ignored
                                    z.changeTag = data.recordChangeTag;

                                    let imageUrl = '';

                                    if (data.fields.Image) {
                                        imageUrl = data.fields.Image.value.downloadURL;
                                    } else if (data.fields.tempFileURL) {
                                        imageUrl = 'https://www.twineandglue.com/upld/files/'
                                                    + data.fields.tempFileURL.value;
                                    }

                                    z.description = data.fields.Description.value;
                                    z.annotation = data.fields.annotation ? data.fields.annotation.value : '';
                                    z.x = data.fields.x.value;
                                    z.y = data.fields.y.value;
                                    z.z = data.fields.z.value;
                                    z.width = data.fields.width.value;
                                    z.height = data.fields.height.value;
                                    z.naturalWidth = data.fields.naturalWidth.value;
                                    z.naturalHeight = data.fields.naturalHeight.value;
                                    z.imageUrl = imageUrl;
                                    z.isDirty = false;
                                    z.busy = false;

                                    i++;
                                }
                                // @todo mutating state directly is bad
                                that.setState({scraps: that.state.scraps});
                            });
                        }
        );
    }

    getSelectionIndexToScrapMap() {
        let dx = null;
        let dy = null;
        let dw = 0;
        let dh = 0;

        let m = {};

        for (let z of this.state.scraps) {
            if (z.active) {
                m[z.selectionNumber] = z;

                if(z.selectionNumber === 0) {
                    dx = z.x;
                    dy = z.y;
                    dw = z.width;
                    dh = z.height;
                }
            }
        }

        return {map:m, dx: dx, dy: dy, dw: dw, dh:dh};
    }

    tileScraps(scraps, startX, startY) {
        // @todo direct manipualtin of state is very bad
        let cp = scraps.slice(0);

        let width = this.maxX;
        const numCols = 3;
        let colWidth = parseInt(width / numCols);

        for (let z of this.state.scraps) {
            if (z.active && z.selectionNumber === 0) {
                colWidth = z.width;
                break;
            }
        }

        let act = [];
        while (cp.length) {
            let idx = parseInt(Math.random() * cp.length);
            act.push(cp[idx]);
            cp.splice(idx, 1);
        }

        let columnHeights = [0, 0, 0];//, 0, 0];

        let x = startX;

        for(let i =0;i < columnHeights.length;i++) {
            columnHeights[i] = startY;
        }

        let ci = 0;

        const MARGIN = 10;

        for (let z of act) {
            ci = 0;
            let minH = columnHeights[0];

            for (let i = 1; i < columnHeights.length; i++) {
                if (minH > columnHeights[i]) {
                    ci = i;
                    minH = columnHeights[i];
                }
            }

            let y = columnHeights[ci];

            let w = z.naturalWidth;
            let h = z.naturalHeight;

            z.x = x + MARGIN + ci * (colWidth + MARGIN);
            z.y = y;
            z.width = colWidth;
            z.height = parseInt(colWidth * h / w);

            columnHeights[ci] += z.height + MARGIN;

            ci++;
            if (ci === numCols) {
                ci = 0;
            }
        }

        this.setState({scraps: this.state.scraps});

        let that = this;
        this.db.updateScraps(act,
            function (records) {
                for (let record of records) {
                    that.__onRecordUpdate(record);
                }
            }
        );
    }

    handleScrapAction(action) {

        let MARGIN = 10;

        //
        if(action === 'tile-selection') {

            let scr = [];
            let primary = null;
            for (let z of this.state.scraps) {
                if (z.active) {
                    scr.push(z);
                    if(z.selectionNumber === 0) {
                        primary = z;
                    }
                }
            }
            this.tileScraps(scr, primary.x, primary.y);
        }
        else if(action === 'tile') {
            this.tileScraps(this.state.scraps, 0, 0);
        }
        else if(action === 'zoom') {
            for (let z of this.state.scraps) {
                if (z.active) {
                    z.isZoomed = z.isZoomed === false;

                    this.setState({scraps: this.state.scraps});
                    break;
                }
            }
        }
        else if(action === 'export') {

            let zip = new JSZip();

            let i = 0;

            let zipped = 0;


            let that = this;
            this.setState({exporting: true});

            let totalFiles = this.state.scraps.length;
            let zipFileName = 'tg-export-' + this.state.name.replace(/[^\w]+/g, '-').toLowerCase() + '.zip';

            for (let z of this.state.scraps) {
                let directDownloadURL = z.imageUrl;

                createZipFileFromServerImages(zip, z.description,
                                                directDownloadURL, z.fileType,
                    function() {

                        zipped++;

                        if(zipped === totalFiles) {
                            zip.generateAsync({type: "blob"})
                                .then(function (blob) {
                                    saveAs(blob, zipFileName);

                                    that.setState({exporting: false});

                                });
                        }
                    });
            }
        }
        else if(action === 'share') {
            this.db.share(this.props.recordName);
        }
        else if(action === 'annotate') {
            for (let z of this.state.scraps) {
                z.isEditingAnnotation = false;

                if (z.active) {
                    z.isEditingAnnotation = true;

                    // @todo direct manipulatin of state is bad
                    this.setState({scraps: this.state.scraps});
                    break;
                }
            }
        }
        else if(action === 'aligntop') {
            let mx = this.getSelectionIndexToScrapMap();

            let dx = mx.dx;
            let dy = mx.dy;
            let dw = mx.dw;
            let dh = mx.dh;
            let m  = mx.map;

            let changedScraps = [];
            // @todo direct manipualtin of state is very bad
            for(let ii = 0; ii < this.numSelections; ii++) {
                let z = m[ii];

                if (z.active) {
                    let c = this.mapClientToPaper(dx, dy, true);
                    z.x = c.x;
                    z.y = c.y;
                    z.isDirty = true;

                    dx = c.x + z.width + MARGIN;

                    changedScraps.push(z);
                }
            }

            this.setState({scraps: this.state.scraps});

            let that = this;
            this.db.updateScraps(changedScraps,
                function (records) {
                    for (let record of records) {
                        that.__onRecordUpdate(record);
                    }
                }
            );
        }
        else if(action === 'alignleft') {
            let mx = this.getSelectionIndexToScrapMap();

            let dx = mx.dx;
            let dy = mx.dy;
            let dw = mx.dw;
            let dh = mx.dh;
            let m  = mx.map;

            let changedScraps = [];
            // @todo direct manipualtin of state is very bad
            for(let ii = 0; ii < this.numSelections; ii++) {
                let z = m[ii];

                if (z.active) {
                    let c = this.mapClientToPaper(dx, dy, true);
                    z.x = c.x;
                    z.y = c.y;
                    z.isDirty = true;

                    dy = c.y + z.height + MARGIN;

                    changedScraps.push(z);
                }
            }

            this.setState({scraps: this.state.scraps});

            let that = this;
            this.db.updateScraps(changedScraps,
                function (records) {
                    for (let record of records) {
                        that.__onRecordUpdate(record);
                    }
                }
            );
        }
        else if(action === 'sizeup') {
            // @todo direct manipualtin of state is very bad
            let changedScraps = [];
            let dx = null;
            let dy = null;
            let dw = 0;
            let dh = 0;
            for (let z of this.state.scraps) {
                if (z.active) {
                    if((dx === null) || (z.x < dx)) {
                        dx = z.x;
                        dy = z.y;
                        dw = z.width;
                        dh = z.height;
                    }
                }
            }
            for (let z of this.state.scraps) {
                if (z.active) {
                    z.width = dw;
                    z.height = dh;
                    z.isDirty = true;

                    changedScraps.push(z);
                }
            }
            this.setState({scraps: this.state.scraps});

            let that = this;
            this.db.updateScraps(changedScraps,
                function (records) {
                    for (let record of records) {
                        that.__onRecordUpdate(record);
                    }
                }
            );
        }
        else if(action === 'widthup') {
            let mx = this.getSelectionIndexToScrapMap();

            let dx = mx.dx;
            let dy = mx.dy;
            let dw = mx.dw;
            let dh = mx.dh;
            let m  = mx.map;

            let changedScraps = [];
            // @todo direct manipualtin of state is very bad
            for(let ii = 0; ii < this.numSelections; ii++) {
                let z = m[ii];

                if (z.active) {
                    z.height = parseInt(dw * z.height / z.width);

                    z.width = dw;
                    z.isDirty = true;

                    changedScraps.push(z);
                }
            }

            this.setState({scraps: this.state.scraps});

            let that = this;
            this.db.updateScraps(changedScraps,
                function (records) {
                    for (let record of records) {
                        that.__onRecordUpdate(record);
                    }
                }
            );
        }
        else if(action === 'heightup') {
            let mx = this.getSelectionIndexToScrapMap();

            let dx = mx.dx;
            let dy = mx.dy;
            let dw = mx.dw;
            let dh = mx.dh;
            let m  = mx.map;

            let changedScraps = [];
            // @todo direct manipualtin of state is very bad
            for(let ii = 0; ii < this.numSelections; ii++) {
                let z = m[ii];

                if (z.active) {
                    z.width = parseInt(dh * z.width / z.height);

                    z.height = dh;
                    z.isDirty = true;

                    changedScraps.push(z);
                }
            }

            this.setState({scraps: this.state.scraps});

            let that = this;
            this.db.updateScraps(changedScraps,
                function (records) {
                    for (let record of records) {
                        that.__onRecordUpdate(record);
                    }
                }
            );
        }
    }

    onBeginAnnotation(scrap) {


        // console.log("ON BEGIN ANNOTATION!!!");

        for (let z of this.state.scraps) {
            if (z.key === scrap.props.name) {
                z.isEditingAnnotation = true;
                break;
            }
        }
        this.setState({scraps: this.state.scraps});
    }

    onAnnotationCancel(scrap) {
        // console.log( 'onannotaiton CANCEL');

        for (let z of this.state.scraps) {
            if (z.key === scrap.props.name) {
                z.isDirty = false;
                z.active = false;
                z.isEditingAnnotation = false;

                break;
            }
        }
        this.setState({scraps: this.state.scraps});
    }

    onEditingTextCancel(scrap) {
        console.log("CANCELLED EDIT");
    }

    onEditingTextDone(scrap, newText) {
        console.log('on eidting text done change', scrap, newText);

        let boardRecordName = this.props.recordName;

        if (scrap.props.isNotSaved) {


// console.log('SAAAAAVING (nope)');
// return;

            let x =23;
            let y = 23;
            let w= 100;
            let h = 100;

            let record = {
                recordType: "Scrap",
                parent: {
                    recordName: boardRecordName
                },
                fields: {
                    Board: {
                        value: {
                            recordName: boardRecordName
                        }
                    },

                    x: {value: x},
                    y: {value: y},
                    z: {value: this.maxZ},

                    // @todo scale down to fit screen
                    width: {value: w},
                    height: {value: h},
                    naturalWidth: {value: w},
                    naturalHeight: {value: h},

                    annotation: {value: newText},
                    Description: {value: newText},
                    type: {value: TYPE_TEXT},
                }
            };

            let nameOfPlaceHolderScrap = scrap.props.name;

            this.db.createScraps([record], function (records) {
                console.log("CREATED RECORD!");

                // @todo direct state manipulation is bad
                for (let z of this.state.scraps) {
                    if(z.key === nameOfPlaceHolderScrap) {
                        console.log('found placeholder');

                    }
                }
            });
        } else {
            this.onAnnotationChange(scrap, newText);
        }
    }

    onAnnotationChange(scrap, newText) {

        for (let z of this.state.scraps) {
            if (z.key === scrap.props.name) {

                if(newText === null) {

                } else {
                    if(newText !== z.annotation) {
                        this.db.updateScrapAnnotation(scrap.props.name,
                            scrap.props.changeTag, newText,
                            this.__onRecordUpdate.bind(this)
                        );
                    }
                    z.annotation = newText;
                }

                z.isDirty = true;
                z.active = false;
                z.isEditingAnnotation = false;

                break;
            }
        }
        this.setState({scraps: this.state.scraps});
    }

    onPie(e) {
        this.setState({showPie: true});
    }

    render() {
        let scraps = [];

        let miniScraps = [];

        let maxX = 1024;
        let maxY = 512;

        let loading = '';

        let HACK_numberOfImagesInTemporaryStorage = 0;
        let HACK_imageUploadBlobs = [];


        if(this.state.isLoading) {
            loading = (
                <div className="board-loading">
                </div>
            );
        }

        let somethingWasZoomed = false;

        let pieStyle = {};

        for (let z of this.state.scraps) {
            somethingWasZoomed |= z.isZoomed;

            if (z.imageUrl.startsWith('https://www.twineandglue.com/upld/files/')) {
                HACK_numberOfImagesInTemporaryStorage++;
            }
        }

        let selectedCount = 0;

        let tileCol = 0;

        let tileX = 0;
        let tileY = 0;
        let maxTileY = 0;

        for (let z of this.state.scraps) {

            if(z.active) {
                selectedCount++;
            }

            let w = z.width;
            let h = z.height;

            let vx = z.x + w;
            let vy = z.y + h;

            if (maxX < vx) {
                maxX = vx;
            }

            if (maxY < vy) {
                maxY = vy;
            }

            let dullOut = somethingWasZoomed && !z.isZoomed;

            let delayLoad = true;

            // setup a halo around the viewport to reduce images
            // snapping in on load
            //
            let leftBound = this.vScrollX - this.vRect.width / 4;
            let rightBound = this.vScrollX + this.vRect.width + this.vRect.width / 4;

            let topBound = this.vScrollY - this.vRect.height / 4;
            let bottomBound = this.vScrollY + this.vRect.height + this.vRect.height / 4;

            if (
                (
                    ((z.x >= leftBound) && (z.x <= rightBound))
                    || ((vx >= leftBound) && (vx <= rightBound))
                    || ((z.x <= leftBound) && (vx >= rightBound))
                )
                &&
                (
                    ((z.y >= topBound) && (z.y <= bottomBound))
                    || ((vy >= topBound) && (vy <= bottomBound))
                    || ((z.y <= topBound) && (vy >= bottomBound))
                )
            ) {
                delayLoad = false;
            }

            z.delayLoad = delayLoad;

            let imgUrl = z.imageUrl;

            if (z.fileType === 'application/pdf') {

                z.delayLoad = true;

            } else if (z.key.startsWith('placeholder_')) {

                // console.log("reovewing", z.description);

            } else if ((z.imageUrl && !delayLoad) || isImageCached(z.key)) {

                let that = this;

                let zw = z.width;
                let zh = z.height;

                if (z.active
                    && this.state.resizing) {
                    zw = z.naturalWidth;
                    zw = z.naturalHeight;
                }

                // @todo rename imgUrl since it's really whatever asset it stored as the blob
                //
                imgUrl = getImageUrlForScrapKey(z.key,
                    zw, zh,
                    z.fileType,
                    z.imageUrl,
                    function (asset_key, HACK_blobToUploadToCloudKitIfThisIsTheOwnerOfTheDatabase) {
                        // @todo probably use immutable array
                        // for state.scraps and update just the loaded scrap

                        if(HACK_blobToUploadToCloudKitIfThisIsTheOwnerOfTheDatabase) {
                            HACK_imageUploadBlobs.push({
                                scrap: {
                                    key: asset_key
                                },
                                blob: HACK_blobToUploadToCloudKitIfThisIsTheOwnerOfTheDatabase
                            });

                            // @hack
                            // @todo all this should be redone to prevent race conditions
                            //
                            console.log('found',HACK_imageUploadBlobs.length,
                                HACK_numberOfImagesInTemporaryStorage);

                            if(HACK_imageUploadBlobs.length === HACK_numberOfImagesInTemporaryStorage) {
                                that.db.uploadFileToScrap(that.dbType, HACK_imageUploadBlobs);
                            }
                        }

                        // console.log('load completed on', asset_key)
                        that.setState({xyz: Math.random()});
                    }
                );

                console.log('LF: ', this.props.recordName);

                localForage.setItem('sample_image_'+this.props.recordName,
                                    z.key);

                if (imgUrl) {
                    delayLoad = false;
                }
            }

            let xx = z.x;
            if(z.isSnappingX) {
                xx = z.snappedX;
            }
            let yy = z.y;
            if(z.isSnappingY) {
                yy = z.snappedY;
            }

            let isSnapping = z.isSnappingY || z.isSnappingX;

            if (this.state.tile) {
                xx = tileX;
                yy = tileY;

                tileCol++;

                if( (tileY + z.height) > maxTileY) {
                    maxTileY = tileY + z.height;
                }
                tileX += z.width;

                if (tileCol === 5) {
                    tileCol = 0;
                    tileX = 0;
                    tileY = maxTileY;
                }

            }

            let waypoint = null;

            // @todo this might be messy/confusing since it's computed state
            // used to pass props AND in minimap display
            z.waypoint = null;

            {
                let displayAnnotation = z.annotation;

                const reWaypoint = /\[(\d+)\]/g;
                const mwp = reWaypoint.exec(displayAnnotation);

                if (mwp) {
                    z.waypoint = mwp[1];
                }
            }

            scraps.push(
                <Scrap type={z.type}
                       isNotSaved={z.isNotSaved}
                       waypoint={z.waypoint}
                       lastModifiedTimeStamp={z.lastModifiedTimeStamp}
                       createdTimeStamp={z.createdTimeStamp}
                       delayLoad={delayLoad}
                       busy={z.busy}
                       dullOut={dullOut}
                       fileType={z.fileType}
                       annotation={z.annotation}
                       isZoomed={z.isZoomed}
                       board={this} key={z.key} isDirty={z.isDirty} name={z.key} description={z.description}
                       x={xx} y={yy} z={z.z} width={w} height={h}
                       imageUrl={imgUrl}
                       naturalWidth={z.naturalWidth}
                       naturalHeight={z.naturalHeight}
                       active={z.active}
                       moving={this.state.moving}
                       changeTag={z.changeTag}
                       onActive={this.setActiveScrap.bind(this)}
                       onAnnotationChange={this.onAnnotationChange.bind(this)}
                       onAnnotationCancel={this.onAnnotationCancel.bind(this)}
                       onBeginAnnotation={this.onBeginAnnotation.bind(this)}
                       isEditingAnnotation={z.isEditingAnnotation}
                       selectionNumber={z.selectionNumber}
                       isSnapping={isSnapping}
                       isCandidate={z.isCandidate}
                       marginLeft={z.marginLeft}
                       showMarginLeft={z.showMarginLeft}
                       showSnapLeft={z.showSnapLeft}
                       snapTop={z.snapTop}
                       isEditable={this.props.isEditable}
                       isEditingText={z.isEditingText}
                       onEditingTextDone={this.onEditingTextDone.bind(this)}
                       onEditingTextCancel={this.onEditingTextCancel.bind(this)}
                />
            );
        }

        let mmapWidth = 200;
        let mmapHeight = parseInt(maxY / maxX * mmapWidth);

        let miniMapStyle = {
            height: mmapHeight,
        };

        let miniMapViewportStyle = {
            left: this.vScrollX / maxX * mmapWidth,
            top: this.vScrollY / maxY * mmapHeight,
            width: this.vRect ? this.vRect.width / maxX * mmapWidth : 0,
            height: this.vRect ? this.vRect.height / maxY * mmapHeight : 0,
        };

        for (let z of this.state.scraps) {

            let w = z.width;
            let h = z.height;

            let st = {
                left: parseInt(z.x / maxX * mmapWidth),
                top: parseInt(z.y / maxY * mmapHeight),
                width: parseInt(w / maxX * mmapWidth),
                height: parseInt(h / maxY * mmapHeight),
            };

            let cl = 'mini-scrap';
            if (z.active) {
                cl += ' mini-scrap-active';
            }


            let deltaHours = ((g_now - z.createdTimeStamp)/1000.0)/60.0/60.0/24.0;
            if(deltaHours < DAYS_CONSIDERED_NEW) {
                cl += ' mini-scrap-hot';
                // st.backgroundColor = '#ff4040';
                // st.borderColor = '#ff4040'
            }

            // if(z.delayLoad) {
            //     st.border = '1px solid red';
            // }
            miniScraps.push(
                <div className={cl} key={z.key} style={st}>
                    {z.waypoint}
                </div>
            );
        }

        if(this.maxX > maxX) {
            if(this.state.moving) {
                maxX = this.maxX;
            }
        }
        if(this.maxY > maxY) {
            if(this.state.moving) {
                maxY = this.maxY;
            }
        }

        let canvasStyle = {
            width: parseInt(maxX + 300),
            height: parseInt(maxY + 300),
        };

        let canvasCssClasses = 'canvas';

        if(this.state.moving) {
            canvasCssClasses += ' canvas-moving';
        }

        this.maxX = maxX;
        this.maxY = maxY;

        let that = this;

        let menu = this.props.isEditable ? (
            <div className="image-pie" style={pieStyle}
                 onMouseDown={this.onPie.bind(this)}
            >
                {this.state.exporting ||
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'export')}>
                    Export
                </div>
                }
                {this.state.exporting &&
                    <div className="selection-action">
                    Export <span className="export-spinner"></span>
                   </div>
                }
                { this.props.isPrivate &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'share')}>
                    share
                </div>
                }
                { selectedCount === 1 && this.state.altDown && this.state.shiftDown &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'tile')}>
                    random tile entire board (no undo)
                </div>
                }
                { selectedCount > 1 && this.state.altDown && this.state.shiftDown &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'tile-selection')}>
                    random tile selection (no undo)
                </div>
                }
                { selectedCount === 1 &&
                <div className="selection-action" onMouseDown={
                    (e) => {
                    that.handleScrapAction('annotate');
                    e.stopPropagation();
                    e.preventDefault();
                    }}>
                    annotate
                </div>
                }
                { selectedCount > 1 &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'aligntop')}>
                    align top
                </div>
                }
                { selectedCount > 1 &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'alignleft')}>
                    align left
                </div>
                }
                { selectedCount > 1 &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'sizeup')}>
                    size up
                </div>
                }
                { selectedCount > 1 &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'widthup')}>
                    width up
                </div>
                }
                { selectedCount > 1 &&
                <div className="selection-action" onMouseDown={this.handleScrapAction.bind(this, 'heightup')}>
                    height up
                </div>
                }
            </div>
        ) : '';

        let selectionArea = "";

        if(this.state.startedAreaSelection) {

            let x1 = this.mouseDownX;
            let y1 = this.mouseDownY;
            let x2 = this.state.areaSelectionEnd.x;
            let y2 = this.state.areaSelectionEnd.y;

            let width = x2 - x1;
            let height = y2 - y1;

            if(x1 > x2) {
                width = x1 - x2;
                x1 = x2;
            }

            if(y1 > y2) {
                height = y1 - y2;
                y1 = y2;
            }

            let selectionStyle = {
                left: x1,
                top: y1,
                width: width,
                height: height,
            };
            selectionArea = (
              <div className="selectionArea" style={selectionStyle}>
              </div>
            );
        }

        let canvas = '';

        if(this.props.isEditable) {
            canvas = (
                <Dropzone className="drop-zone"
                      disableClick
                      onDrop={this.onDrop.bind(this)}
                >
                    <div className={canvasCssClasses} style={canvasStyle}
                         ref={(input) => {this.canvasDOM = input;}}
                         onMouseMove={this.handleMouseMove.bind(this)}
                         onMouseUp={this.handleMouseUp.bind(this)}
                         onMouseLeave={this.handleMouseUp.bind(this)}
                         onMouseDown={this.handleMouseDown.bind(this)}
                         onDrop={this.handleMouseDrop.bind(this)}
                         onSelect={(e) => {e.preventDefault();}}
                    >
                        {scraps}
                        {selectionArea}
                        <div className="canvas-edge-marker-bottom"></div>
                        <div className="canvas-edge-marker-right"></div>
                    </div>
                </Dropzone>
            );
        } else {
            canvas = (
                <div className={canvasCssClasses} style={canvasStyle}
                     ref={(input) => {this.canvasDOM = input;}}
                     onSelect={(e) => {e.preventDefault();}}
                >
                    {scraps}
                    {selectionArea}
                    <div className="canvas-edge-marker-bottom"></div>
                    <div className="canvas-edge-marker-right"></div>
                </div>
            );
        }

        return (
            <div className="App"
                 onSelect={(e) => {e.preventDefault();}} >
                <div className="board-header"
                     onSelect={(e) => {e.preventDefault();}} >
                  <span className="logo">
                        <Link to={`/`}>
                              t&amp;g
                        </Link>
                  </span>
                    <span className="release-decal">
                        <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Software_release_life_cycle#Alpha">alpha</a>
                    </span>
                    <ClickEditor text={this.state.name} onSave={this.updateBoardName}/>
                    {menu}
                </div>
                {loading}

                {(this.state.moving || this.state.resizing || this.state.scrolling) &&
                <div className="mini-map" style={miniMapStyle}>
                    <div className="mini-map-viewport" style={miniMapViewportStyle}></div>
                    {miniScraps}
                </div>
                }

                <div className="viewport" ref={(input) => {
                    this.viewportDOM = input;
                }}>
                    {canvas}
                </div>
            </div>
        );
    }

    handleKeyboardShortcuts(e) {
        // console.log(e.which);

        const X_MARGIN = 80; // @todo improve this offset calculation

        if(e.shiftKey) {
            if(e.which === 37) { // left arrow

                let currentWayPoint = parseInt(this.state.currentWayPoint);

                let nextWayPoint = -1;

                let xx = 0;
                let yy = 0;

                let ct = 0;

                for(let z of this.state.scraps) {
                    let mwp = WAYPOINT_RE.exec(z.annotation);

                    if(mwp) {
                        let pt = parseInt(mwp[1]);

                        if(((currentWayPoint === -1) || (pt < currentWayPoint))
                            && (pt > nextWayPoint)) {
                            xx = z.x;
                            yy = z.y;
                            nextWayPoint = pt;
                        }
                        // console.log('annot', pt, z.x, z.y, currentWayPoint, nextWayPoint);

                    }

                    ct++;
                }

                if(nextWayPoint !== -1) {

                    xx = Math.max(xx - X_MARGIN, 0);
                    yy = Math.max(yy - X_MARGIN, 0);

                    this.viewportDOM.scrollLeft = xx;
                    this.viewportDOM.scrollTop = yy;

                    this.setState({currentWayPoint: nextWayPoint});
                }
            }
            else if(e.which === 39) { // right arrow
                let currentWayPoint = parseInt(this.state.currentWayPoint);

                let nextWayPoint = 99999999;

                let xx = 0;
                let yy = 0;

                let ct = 0;

                for(let z of this.state.scraps) {
                    let mwp = WAYPOINT_RE.exec(z.annotation);

                    if(mwp) {
                        let pt = parseInt(mwp[1]);

                        if(((currentWayPoint === -1) || (pt > currentWayPoint))
                            && (pt < nextWayPoint)) {
                            xx = z.x;
                            yy = z.y;
                            nextWayPoint = pt;
                        }
                        // console.log('annot', pt, z.x, z.y, currentWayPoint, nextWayPoint);

                    }

                    ct++;
                }

                if(nextWayPoint !== 99999999) {

                    xx = Math.max(xx - X_MARGIN, 0);
                    yy = Math.max(yy - X_MARGIN, 0);

                    this.viewportDOM.scrollLeft = xx;
                    this.viewportDOM.scrollTop = yy;

                    this.setState({currentWayPoint: nextWayPoint});
                }
            }
        }
    }

    componentWillMount() {
        window.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);
        window.addEventListener('resize', this.onResize);
    }

    componentDidMount() {
        this.viewportDOM.addEventListener('scroll', this.onScroll);

        this.vRect = this.viewportDOM.getBoundingClientRect();

        document.addEventListener('keydown', this.handleKeyboardShortcuts);
    }

    componentWillUnmount() {

        this.db.teardownNotificationHandler();

        clearTimeout(this.showMapTimer);

        window.removeEventListener('keydown', this.onKeyDown);
        window.removeEventListener('keyup', this.onKeyUp);
        window.removeEventListener('resize', this.onResize);

        document.removeEventListener('keydown', this.handleKeyboardShortcuts);

        this.viewportDOM.removeEventListener('scroll', this.onScroll);
    }

    onResize(e) {
        this.vRect = this.viewportDOM.getBoundingClientRect();
    }

    onScroll(e) {
        clearTimeout(this.onScrollTimer);
        clearTimeout(this.onHideMinimapTimer);

        let n = new Date().getTime();

        if((n-this.lastScrollTime) > 200) {
            setTimeout(this.__batchHandleOnScroll, 0);
        } else {
            this.onScrollTimer = setTimeout(
                            this.__batchHandleOnScroll,
                            25);
        }
    }

    __batchHandleOnScroll() {
        // console.log('scroll updated');
        this.lastScrollTime = new Date().getTime();
        this.onScrollTimer = null;
        this.vScrollX = this.viewportDOM.scrollLeft;
        this.vScrollY = this.viewportDOM.scrollTop;
        this.setState({scrolling: true});

        let that = this;
        this.onHideMinimapTimer = setTimeout(function() {
                that.setState({scrolling:false});
            },
            1000
        );
    }

    onKeyDown(e) {
    //     console.log('SK',   e.ctrlKey,
    //         e.shiftKey,
    //         e.altKey,
    //         e.metaKey,
    // );
        if(e.shiftKey) {
            this.setState({shiftDown: true});
        }
        if(e.altKey) {
            this.setState({altDown: true});
        }
    }

    onKeyUp(e) {
        // console.log('SK', e.shiftKey);
        if(!e.shiftKey) {
            this.setState({shiftDown: false});
        }

        if(!e.altKey) {
            this.setState({altDown: false});
        }
    }
}

class BRD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedInNow: !!window.g_USER_INFO
        };
        window.loggedInNow = this.loggedInNow.bind(this);
    }

    loggedInNow() {
        this.setState({loggedInNow:true});
    }
}

class SharedBoard extends BRD {
    render() {
        return <Board ownerRecordName={this.props.match.params.ownerRecordName}
                      recordName={this.props.match.params.boardName}
                      isShared={true}
                      isEditable={this.state.loggedInNow}
        />
    }
}

class PrivateBoard extends BRD {
    render() {
        return <Board recordName={this.props.match.params.boardName} isPrivate={true}
                      isEditable={this.state.loggedInNow}
        />
    }
}

class PublicBoard extends BRD {
    render() {
        return <Board recordName={this.props.match.params.boardName} isPrivate={false}
                      isEditable={this.state.loggedInNow}
        />
    }
}


class ClickEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            text: props.text
        };

        console.log('rproprs', props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChange   = this.onChange.bind(this);
        this.onClick    = this.onClick.bind(this);
        this.onSaveAction    = this.onSaveAction.bind(this);
        // this.onCreateNewBoard = this.onCreateNewBoard.bind(this);
        // this.onCreateNewPrivateBoard = this.onCreateNewPrivateBoard.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.text !== this.props.text) {
            this.setState({text: nextProps.text});
        }
    }

    onKeyDown(e) {
        if(e.keyCode === 27 /* esc */) {
            this.setState({text: this.props.text,editing:false});
        }
        else if(e.keyCode === 13 /* enter */) {
            this.props.onSave(this.state.text);
            this.setState({editing:false});
        }
    }

    onChange(e) {
        this.setState({text: e.target.value});
    }

    onSaveAction(e) {
        if(this.props.onSave) {
            this.props.onSave(this.state.text);
        }

        this.setState({editing: false});
    }

    onClick(e) {
        if(this.state.editing) {

        } else {
            this.setState({editing: true});
        }
    }

    render() {
        if(this.state.editing) {
            return (
                <div className="click-editor-field">
                    <input type="text" value={this.state.text} onChange={this.onChange}
                           onBlur={this.onSaveAction}
                           onKeyDown={this.onKeyDown}
                           ref={input => input && input.focus()
                    }
                    />
                </div>
            );
        } else {
            return (
                <div className="click-editor-field" onClick={this.onClick}>
                    {this.props.text}
                </div>
            );
        }
    }
}

class Home extends Component {
    constructor() {
        super();

        this.state = {
            boards: List(),
            isLoading: true,
        };

        this.db = new window.Database('PUBLIC');
        this.db.fetchBoards(this.__updateBoardList.bind(this));

        this.onCreateNewBoard = this.onCreateNewBoard.bind(this);
        this.onCreateNewPrivateBoard = this.onCreateNewPrivateBoard.bind(this);
    }

    onCreateNewBoard() {
        this.createNewBoard('Untitled', '');
    }

    onCreateNewPrivateBoard() {
        this.createNewBoard('Untitled-Private', '', true);
    }

    createNewBoard(name, description, isPrivate) {
        let that = this;

        this.db.createBoard(
            (!!isPrivate) ? 'PRIVATE' : 'PUBLIC',
            name, description,
            function(records) {
                let bds = that.state.boards;

                for(let record of records) {
                    let b = window.__mapRecordToBoard(record);
                    b.isPrivate = !!isPrivate;

                    that.setState({goToBoard: b});

                    return;
                }
            }
        );
    }

    __updateBoardList(records) {
        // console.log('sizeeeeeeeee', records);

        let that = this;
        for(let b of records) {
            let rec = b;
            localForage.getItem('sample_image_'+b.recordName)
                .then(function (asset_key){
                    console.log("RRR", asset_key);
                    if(asset_key) {
                        let img_url = getImageUrlForScrapKey(asset_key, 100, 100, 'image/png', null,
                        function() {
                            let u_url = IMAGE_OBJ[asset_key];
                            if(u_url){
                                rec.img_url = u_url;
                                that.setState({xyz: Math.random()});
                            }
                        });
                        if(img_url){
                            rec.img_url = img_url;
                            that.setState({xyz: Math.random()});
                        }
                    }
                });
        }

        this.setState({
            boards: List(records),
            isLoading: false
        });
    }

    render() {

        if(this.state.goToBoard) {
            if(this.state.goToBoard.isPrivate) {
                return <Redirect push to={`/private-board/${this.state.goToBoard.recordName}`} />;

            } else {
                return <Redirect push to={`/board/${this.state.goToBoard.recordName}`} />;
            }
        }

        let boards = [];

        let loading = '';

        if(this.state.isLoading) {
            loading = (
                <div className="board-loading">
                </div>
            );

        } else {


            boards = this.state.boards.map(
                b => {
                    let url = `/board/${b.recordName}`;

                    let styles= {};
                    if(b.img_url) {
                        styles = {
                            backgroundImage: 'url(' + b.img_url + ')',
                        }
                    }

                    if(b.isPrivate) {
                        url = `/private-board/${b.recordName}`;

                    } else if(b.isShared) {
                        console.log("IS AHRED", b.description);
                        url = `/shared/${b.recordName}/${b.ownerRecordName}`;
                    }

                    let clsType = (b.isPrivate ? 'private' : (b.isShared ? 'shared' : 'public'));

                    return (
                        <Link to={url}>
                            <li className={'board-entry board-access-' + clsType}
                                key={b.recordName}>
                                <div className="board-image-snippet" style={styles}>
                                </div>
                                <div className="board-image-snippet-fader">
                                </div>
                                <div className="board-name">
                                        {b.name}
                                </div>
                                <div className="board-info">
                                    {/*{b.description}*/}
                                    {/*{b.img_url}*/}
                                </div>
                            </li>
                        </Link>
                    );
                }
            );
        }

        return (
            <div>
                <div className="board-header board-header-home">
                  <span className="logo">
                      <Link to={`/`}>
                          t&amp;g
                      </Link>
                  </span>
                    <span className="release-decal">
                        <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Software_release_life_cycle#Alpha">alpha</a>
                    </span>
                    <div className="action-bar">
                    {
                        window.g_USER_INFO &&
                        <a className="board-action" href="#" onClick={this.onCreateNewBoard}>create new public board</a>
                    }
                    {
                        window.g_USER_INFO &&
                        <a className="board-action" href="#" onClick={this.onCreateNewPrivateBoard}>create new private board</a>
                    }
                    </div>
                </div>
                {loading}
                <ul className="board-list">
                    {boards}
                    {/*<li className="board-entry board-entry-blank" />*/}
                </ul>
            </div>
        );
    }
}

class AcceptInvitation extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        let guid = props.match.params.guid;

        let that = this;

        window.connect(function () {
            console.log(guid);
            let db = new window.Database('PUBLIC');
            db.acceptShares(guid, function(data) {
                that.setState({
                    goToBoard: {
                        recordName: data.rootRecordName,
                        ownerRecordName: data.zoneID.ownerRecordName
                    }
                });
            });
        });
    }

    render() {
        if(this.state.goToBoard) {
            return <Redirect push to={`/shared/${this.state.goToBoard.recordName}/${this.state.goToBoard.ownerRecordName}`} />;
        }

        return <div></div>
    }
}

class App extends Component {
    render() {
        return (
            <Router history={createHashHistory()}>
                <Switch>
                    <Route path='/shared/:boardName/:ownerRecordName' component={SharedBoard} />
                    <Route path='/private-board/:boardName' component={PrivateBoard} />
                    <Route path='/board/:boardName' component={PublicBoard} />
                    <Route path='/accept/:guid' component={AcceptInvitation} />
                    <Route path='/' component={Home} />
                </Switch>
            </Router>
        );
    }
}

export default App;
