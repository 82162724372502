import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';


ReactDOM.render(
  <App />,
  document.getElementById('root')
);

/*


import {runstuff, lookupRecord, getUploadTokens, uploadFile, updateRecord,
    getNewReference
} from './connect';

runstuff();

function xyz() {

    document.getElementById('xx').addEventListener('click', function (e) {
        console.log('-->', window.auth_url);
        let wx = window.open(window.auth_url);

        lookupRecord()
            .then(function (r) {
                console.log("herere", r);
            });
    });


    document.getElementById('yy').addEventListener('click', function (e) {

         // lookupRecord()
         // .then(function(r) {
         // console.log("herere", r);
         // });

        getNewReference()
            .then(function (r) {
                console.log('doing some refrence', r.data.assets[0]);
                let up = r.data.assets[0];
                delete up.recordName;
                delete up.fieldName;

                return up;
            })
            .then(updateRecord)
        ;

    });

    document.getElementById('upload').addEventListener('click', function (e) {

        console.log('submitted');

        getUploadTokens()
            .then(function (r) {
                let f = document.getElementById('f').files[0];
                return uploadFile(r.data.tokens[0].url, f);
            })
            .catch(function (e) {
                console.log(e);
            })
            .then(function (r) {
                console.log("found uplaod ifo", r);
                return r.data.singleFile;
            })
            .then(updateRecord);


        e.preventDefault();
    });

}

*/